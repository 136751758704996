import React from "react"
import { FaMapPin } from "react-icons/fa"
import EGO1Img from "../images/evt-ego.jpeg"
import EGO2Img from "../images/sulniac.png"

const evenements = [
  {
    titre: "Ensemble Grand-Ouest #3",
    picture: EGO2Img,
    dateDebut: "10/02/2023",
    dateFin: "12/02/2023",
    lieu: "Sulniac",
    url: "/inscription",
    tags: ["priere", "louange", "unite"],
  },
  // {
  //   titre: "Ensemble Grand-Ouest #1",
  //   picture: EGO1Img,
  //   dateDebut: "01/04/2022",
  //   dateFin: "02/04/2022",
  //   lieu: "Rennes",
  //   url: "/inscription",
  //   tags: ["priere", "louange", "unite"],
  // },
]

const Evenements = () => {
  return (
    <div className="evenements-conteneur">
      {evenements.map(evenement => (
        <div className="event-card">
          <div className="img">
            <img src={evenement.picture} alt="" />
          </div>
          <h3>{evenement.titre}</h3>
          <p>
            {evenement.dateDebut +
              (evenement.dateFin !== null ? " - " + evenement.dateFin : null)}
          </p>
          <p>
            <FaMapPin />
            {evenement.lieu}
          </p>
          <div className="bouton-block">
            <p>
              <a className="bouton bleu" href={evenement.url}>
                Inscription
              </a>
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Evenements
