import React, { useState, useEffect } from "react"

import Titre from "../components/titre"
import Navigation from "../components/navigation"

const Navbar = () => {
  const [stickyClass, setStickyClass] = useState("relative")

  const stickNavbar = () => {
    // alert("scroll")
    if (window !== undefined) {
      let windowHeight = window.scrollY
      windowHeight > 500
        ? setStickyClass("fixed top-0 left-0 z-50")
        : setStickyClass("relative")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar)

    return () => {
      window.removeEventListener("scroll", stickNavbar)
    }
  }, [])
  return (
    <div className={"header " + stickyClass} sticky="top">
      <Titre></Titre>
      <Navigation
        active="accueil"
        // sticky={isSticky}
      ></Navigation>
    </div>
  )
}

export default Navbar
