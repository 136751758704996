import * as React from "react"
import { Helmet } from "react-helmet"

import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"

import Evenements from "../components/evenements"

import PTEPImg from "../images/PTEP_LOGO2017.png"
import ATImg from "../images/logo_extend.png"
import FPImg from "../images/Fraternite Pentecote.png"
import HaberakkaImg from "../images/Haberakha.png"
import SteveImg from "../images/steve-djafa.jpg"
import CollectifImg from "../images/collectif.png"
import EGOImg from "../images/sulniac.png"

import "../styles/styles.css"
import Footer from "../components/footer"
import Seo from "../components/seo"
import Newsletter from "../components/newsletter"
import Navbar from "../components/navbar"

import { BsChevronCompactDown } from "react-icons/bs"
import { Link } from "gatsby"

const IndexPage = () => {
  return (
    <div>
      <Helmet>
        <title>Accueil - Ensemble Grand Ouest</title>
      </Helmet>
      <Seo title="Accueil - Ensemble Grand Ouest"></Seo>
      <header className="fullscreen">
        <Navbar />
        <div id="presentation">
          <h2 style={{ display: "none" }}>Présentation</h2>

          <Carousel
            dynamicHeight={false}
            showThumbs={false}
            showArrows
            showStatus={false}
            useKeyboardArrows
            autoFocus
            autoPlay
            interval={5000}
            infiniteLoop
          >
            <div
              className="carousel-item"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginBottom: "4rem",
                paddingBottom: "4rem",
              }}
            >
              <p>
                Ensemble Grand-Ouest est un{" "}
                <em>
                  <b>collectif</b>
                </em>{" "}
                de ministères{" "}
                <em>
                  <b>chrétiens</b>
                </em>{" "}
                œuvrant dans{" "}
                <em>
                  <b>l'unité</b>
                </em>
                .
              </p>
              <p>
                <a className="bouton bleu" href="/presentation">
                  En savoir plus
                </a>
              </p>
            </div>
            {/* <div
              className="carousel-item"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                paddingBottom: "4rem",
              }}
            >
              <img
                src={SteveImg}
                alt="Steve Djafa"
                style={{ maxWidth: "350px" }}
              />
              <div style={{ marginLeft: "2rem", textAlign: "left" }}>
                <p>
                  <em>
                    <b>Steve Djafa</b>
                  </em>
                  , pasteur adjoint à{" "}
                  <Link to="https://parisevangile.fr">
                    l'église Paris Évangile
                  </Link>
                  , sera notre invité.
                  <br /> Rendez-vous les{" "}
                  <em>
                    <b>1er et 2 Avril à Rennes et le 3 Avril à Monterfil</b>
                  </em>{" "}
                  !
                </p>
                <p>
                  <a className="bouton bleu" href="/inscription">
                    Inscription
                  </a>
                </p>
              </div>
            </div> */}
            <div
              className="carousel-item"
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                paddingBottom: "4rem",
              }}
            >
              <img
                src={EGOImg}
                alt="événement Ensemble Grand Ouest"
                style={{ maxWidth: "350px" }}
              />
              <div style={{ marginRight: "2rem", textAlign: "right" }}>
                <p>
                  Les places sont{" "}
                  <em>
                    <b>limitées</b>
                  </em>
                  , n'attendez plus pour vous{" "}
                  <em>
                    <b>inscrire</b>
                  </em>{" "}
                  !
                </p>
                <p>
                  <a className="bouton bleu" href="/inscription">
                    Inscription
                  </a>
                </p>
              </div>
            </div>
          </Carousel>
        </div>
        <section id="partenaires">
          <img src={ATImg} alt="Paris tout est possible" />
          <img src={FPImg} alt="Fraternité Pentecôte" />{" "}
          <img src={HaberakkaImg} alt="Haberakha" />
          <img src={PTEPImg} alt="Amen Toi" />
        </section>
        {/* <BsChevronCompactDown
          style={{
            marginBottom: ".5em",
            lineHeight: "0.2em",
            color: "#00000077",
            fontSize: "2.5em",
            position: "relative",
            bottom: "0px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        /> */}
      </header>
      <main>
        <section className="yellow section" id="evenements">
          <Evenements></Evenements>
        </section>
        <Newsletter></Newsletter>
      </main>
      <Footer></Footer>
    </div>
  )
}

export default IndexPage
